<template>
  <div>
    <div class="img1">
      <div class="h1">硕博毕业辅导</div>
      <div class="h2"><span>1v1专业辅导</span><span>为您解忧</span></div>
      <div class="img_tip">全科覆盖+文章发表支持</div>
      <div class="h3">
        <div class="h3_item">
          <img src="@/assets/image/icon102.png" alt="" />核心期刊
        </div>
        <div class="h3_item">
          <img src="@/assets/image/icon102.png" alt="" />学术会议
        </div>
        <div class="h3_item">
          <img src="@/assets/image/icon102.png" alt="" />硕博毕业
        </div>
      </div>
    </div>
    <div class="box1">
      <div class="box1_left">
        <img src="@/assets/image/icon76.png" alt="" />
        <div class="box1_left_con">
          <div>大牛导师，全科覆盖</div>
          <div>与国内外知名高校导师近距离接触，全科覆盖</div>
        </div>
      </div>
      <div class="box1_right">
        <img src="@/assets/image/icon77.png" alt="" />
        <div class="box1_left_con">
          <div>全程跟踪，定期反馈</div>
          <div>1对1全程跟踪咨询，24小时答疑，专属时间规划进度管理</div>
        </div>
      </div>
    </div>
    <div class="box1" style="margin-top: 40px; margin-bottom: 20px">
      <div class="box1_left">
        <img src="@/assets/image/icon78.png" alt="" />
        <div class="box1_left_con">
          <div>精致教学，能力提升</div>
          <div>深耕硕博辅导教研，拒绝学术不端，全面提升学员专业能力</div>
        </div>
      </div>
      <div class="box1_right">
        <img src="@/assets/image/icon79.png" alt="" />
        <div class="box1_left_con">
          <div>产出保障，发表优势</div>
          <div>
            更高的辅导质量+更成熟的发表渠道+更可靠的学术经验+更合理的辅导费用
          </div>
        </div>
      </div>
    </div>
    <div class="contain">
      <div class="h1">
        <div class="h1_top">
          <img src="@/assets/image/icon67.png" alt="" />
          <div>硕博辅导介绍</div>
          <img src="@/assets/image/icon68.png" alt="" />
        </div>
        <img src="@/assets/image/icon69.png" alt="" class="h1_bottom" />
      </div>
      <div class="h2">
        在校大学老师一对一辅导，为您读研究生和博士期间遇到困难解忧。包含毕业资料整理，题目确定及思路梳理，开题报告分析，论据论点分析，期刊写作及投稿指导，答辩指导，专利申请指导等
      </div>
      <div class="h1">
        <div class="h1_top">
          <img src="@/assets/image/icon67.png" alt="" />
          <div>辅导项目介绍</div>
          <img src="@/assets/image/icon68.png" alt="" />
        </div>
        <img src="@/assets/image/icon69.png" alt="" class="h1_bottom" />
      </div>
      <div class="table">
        <div class="table_top">
          <div>项目级别</div>
          <div>服务项目</div>
          <div>项目介绍</div>
        </div>
        <div class="table_item">
          <div>A项</div>
          <div>前端服务</div>
          <div>
            题目选择、思路沟通、开题报告写作指导、数据分析、模型分析、毕业文章意见分析及指出，与硕士导师基本保持同等指导；直至指导老师认可
          </div>
        </div>
        <div class="table_item">
          <div>B项</div>
          <div>前端+中端服务</div>
          <div>
            在A项基础上，进行具体资料协助提供（数据类型、模型创建、公式），每一章节具体分析指导，直到外审老师审核完毕
          </div>
        </div>
        <div class="table_item">
          <div>C项</div>
          <div>全程服务</div>
          <div>
            在A+B基础上，持续辅导到答辩台上，全程参与指导，包括从前期题目确定到最终答辩定稿
          </div>
        </div>
      </div>
      <div class="h1">
        <div class="h1_top">
          <img src="@/assets/image/icon67.png" alt="" />
          <div>服务详细介绍</div>
          <img src="@/assets/image/icon68.png" alt="" />
        </div>
        <img src="@/assets/image/icon69.png" alt="" class="h1_bottom" />
      </div>
      <div class="tip1">
        <div class="tip1_title">A项前端服务</div>
        <div class="tip_list">
          <div class="tip_item1">
            <div class="tip_item1_left">
              <span>1.选题和课题选择</span>
              <span
                >帮助学生确定合适的研究选题，讨论可能的研究方向和兴趣点，并符合学生背景和专业的课题。</span
              >
            </div>
            <div class="tip_item1_left">
              <span>2.明确研究方向和目标</span>
              <span
                >与学生一起明确研究方向和文章的整体目标，确保研究内容和重点的合理性和可行性。</span
              >
            </div>
          </div>
          <div class="tip_item1">
            <div class="tip_item1_left">
              <span>3.开题报告</span>
              <span
                >指导学生撰写开题报告包括问题陈述，研究目标，研究方法和计划等内容，确保开题报告符合学校要求。</span
              >
            </div>
            <div class="tip_item1_left">
              <span>4.文献综述和查找</span>
              <span
                >引导学生进行文献综述帮助学生找到相关的学术文献和资料，并指导学生对文献进行分析和总结。</span
              >
            </div>
          </div>
          <div class="tip_item1">
            <div class="tip_item1_left">
              <span>5.确定文章结构</span>
              <span
                >构建文章的逻辑框架，确定文章章节主题，并于整体研究目标紧密相关。</span
              >
            </div>
            <div class="tip_item1_left">
              <span>6.疑难解答指导</span>
              <span
                >帮助学生澄清问题的本质，根据学生的问题，提供相应的解决方案和建议，直到指导老师通过。</span
              >
            </div>
          </div>
          <!-- <div class="tip_item2"></div>
          <div class="tip_item3"></div> -->
        </div>
      </div>
      <div class="tip2">
        <div class="tip2_title">B项前端+中端服务</div>
        <div class="tip_list">
          <div class="tip_item">
            <span>1.A项服务中所有项目</span>
            <span
              >在A项服务基础上更进一步提供具体和深入的指导，确保研究的顺利进行。</span
            >
          </div>
          <div class="tip_item">
            <span>2.章节分析指导</span>
            <span
              >对文章每一章节的写作进行具体分析和指导，确保文章的逻辑结构和内容合理。</span
            >
          </div>
          <div class="tip_item">
            <span>3.外审老师审核完毕</span>
            <span
              >提供持续的指导，直到外审老师审核完毕，确保文章的质量和进度。</span
            >
          </div>
        </div>
      </div>
      <div class="tip3">
        <div class="tip3_title">C项全程服务</div>
        <div class="tip_list">
          <div class="tip_item1">
            <span>1.B项服务中所有项目</span>
            <span
              >在B项服务基础上进一步提供具体和深入的指导，确保研究的高效优质完成。</span
            >
          </div>
          <div class="tip_item2">
            <div class="tip_item2_left">
              <span>2.全程指导</span>
              <span
                >针对学生从选题到答辩全过程提供连续，全面的指导和支持，确保学生在整个研究过程中得到专业的帮助，顺利完成文章。</span
              >
            </div>
            <div class="tip_item2_left">
              <span>3.修改润色</span>
              <span
                >指导修改和润色，直到文章最终定稿，确保文章质量达到要求。</span
              >
            </div>
          </div>
          <div class="tip_item1">
            <span>4.答辩指导</span>
            <span
              >为学生提供答辩的准备指导，包括演讲技巧和答辩中可能遇到的问题。</span
            >
          </div>
        </div>
      </div>
      <div class="btn" @click="show = !show">
        <img src="@/assets/image/icon70.png" alt="" />
        联系客服
        <div style="position: absolute;left: 380px;">
          <telToast :show="show" v-if="show" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import telToast from "@/components/telToast.vue";

export default {
  components: {
    telToast,
  },
  data () {
    return {
      show: false,
    }
  }
};
</script>

<style lang="scss" scoped>
.img1 {
  width: 100%;
  height: 500px;
  background: url("../../assets/image/icon75.png");
  background-size: 100% 500px;
  background-repeat: no-repeat;
  padding: 10px 0 0;
  box-sizing: border-box;
  .h1 {
    font-size: 99px;
    font-family: zihunxinquhei, zihunxinquhei-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    letter-spacing: 4.46px;
    text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
  }
  .h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    span:nth-child(1) {
      font-size: 53px;
      font-family: zihunxinquhei, zihunxinquhei-Regular;
      font-weight: 400;
      color: #587ef9;
      letter-spacing: 2.39px;
      text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
    }
    span:nth-child(2) {
      font-size: 53px;
      font-family: zihunxinquhei, zihunxinquhei-Regular;
      font-weight: 400;
      color: #fff;
      letter-spacing: 2.39px;
      text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
    }
  }
  .img_tip {
    margin: 6px auto 0;
    width: 509px;
    height: 54px;
    background: url("../../assets/image/icon101.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 27px 0 27px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: zihunxinquhei, zihunxinquhei-Regular;
    font-weight: 400;
    text-align: center;
    color: #23dc5a;
    letter-spacing: 1.62px;
    text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
  }
  .h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .h3_item {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 1.2px;
      margin-right: 50px;
      img {
        width: 43px;
        height: 43px;
        margin-right: 5px;
      }
    }
  }
}
.box1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  margin: 70px auto 40px;
  .box1_left {
    width: 690px;
    height: 223px;
    background: linear-gradient(180deg, #23c48c, #33da61);
    border-radius: 6px;
    position: relative;
    img {
      width: 223px;
      height: 223px;
      position: absolute;
      left: 20px;
      top: -20px;
    }
    .box1_left_con {
      margin: 61px 73px 0 317px;
      display: flex;
      flex-direction: column;
      align-items: center;
      div:nth-child(1) {
        font-size: 22px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.99px;
        margin-bottom: 20px;
      }
      div:nth-child(2) {
        width: 300px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.81px;
      }
    }
  }
  .box1_right {
    width: 690px;
    height: 223px;
    background: linear-gradient(180deg, #23c48c, #33da61);
    border-radius: 6px;
    position: relative;

    img {
      width: 223px;
      height: 223px;
      position: absolute;
      right: 20px;
      top: -20px;
    }
    .box1_left_con {
      margin: 61px 289px 0 45px;
      display: flex;
      flex-direction: column;
      align-items: center;
      div:nth-child(1) {
        font-size: 22px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.99px;
        margin-bottom: 20px;
      }
      div:nth-child(2) {
        width: 300px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.81px;
      }
    }
  }
}
.contain {
  width: 1400px;
  height: 1765px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto 50px;
  padding: 40px 62px;
  box-sizing: border-box;
  .h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .h1_top {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      div {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #007938;
        letter-spacing: 1.08px;
        margin: 0 20px;
      }

      img {
        width: 19px;
        height: 19px;
      }
    }
    .h1_bottom {
      width: 156px;
      height: 5px;
    }
  }
  .h2 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
    letter-spacing: 0.81px;
    margin: 23px 0 50px 0;
  }
  .table {
    margin: 23px 0 50px 0;
    .table_top {
      width: 1276px;
      height: 58px;
      background: #20ad7c;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #ffffff;
      div:nth-child(1) {
        width: 249.5px;
        text-align: center;
      }
      div:nth-child(2) {
        width: 268px;
        text-align: center;
      }
      div:nth-child(3) {
        text-align: center;
        width: 756px;
      }
    }
    .table_item {
      width: 1276px;
      min-height: 99px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      border-bottom: 1px solid #20ad7c;
      div:nth-child(1) {
        width: 249.5px;
        border-left: 1px solid #20ad7c;
        border-right: 1px solid #20ad7c;
        min-height: 99px;
        text-align: center;
        line-height: 99px;
      }
      div:nth-child(2) {
        width: 268px;
        min-height: 99px;
        text-align: center;
        border-right: 1px solid #20ad7c;
        line-height: 99px;
      }
      div:nth-child(3) {
        text-align: center;
        min-height: 99px;
        border-right: 1px solid #20ad7c;
        width: 756px;
        padding: 35px 120px 20px 122px;
        box-sizing: border-box;
      }
    }
  }
  .tip1 {
    margin: 23px 0 20px;
    .tip1_title {
      font-size: 22px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #23c48c;
      text-align: center;
      margin-bottom: 20px;
    }
    .tip_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tip_item1 {
        width: 412px;
        height: 235px;
        background: #20ad7c;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        padding: 38px 40px;
        box-sizing: border-box;
        .tip_item1_left {
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            width: 180px;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            color: #ffffff;
            margin-bottom: 10px;
          }
          span:nth-child(2) {
            width: 138px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }
  .tip2 {
    margin: 0 0 20px;
    .tip2_title {
      font-size: 22px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #23c48c;
      text-align: center;
      margin-bottom: 20px;
    }
    .tip_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tip_item {
        width: 412px;
        height: 235px;
        background: #20ad7c;
        border-radius: 6px;
        padding: 51px 119px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        span:nth-child(1) {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 10px;
        }
        span:nth-child(2) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
  .tip3 {
    margin: 0 0 20px;
    .tip3_title {
      font-size: 22px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #23c48c;
      text-align: center;
      margin-bottom: 20px;
    }
    .tip_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tip_item1 {
        width: 412px;
        height: 235px;
        background: #20ad7c;
        border-radius: 6px;
        padding: 24px 118px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        span:nth-child(1) {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 10px;
        }
        span:nth-child(2) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .tip_item2 {
        width: 412px;
        height: 235px;
        background: #20ad7c;
        border-radius: 6px;
        padding: 24px 35px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .tip_item2_left {
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            color: #ffffff;
            margin-bottom: 10px;
          }
          span:nth-child(2) {
            width: 141px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.btn {
  width: 366px;
  height: 65px;
  background: linear-gradient(90deg, #23c48c, #33da61);
  border-radius: 6px;
  font-size: 18px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 0.9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px auto 0;
  cursor: pointer;
  position: relative;
  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}
</style>